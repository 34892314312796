import MainHeader from "@/components/main-header/MainHeader"
import MainFooter from "@/components/main-footer/MainFooter"
import ModalForm from "@/components/modal-form/ModalForm.vue"

export default {
	name: 'MainContainer',
	components: {
		MainHeader,
		MainFooter,
		ModalForm
	},
	data() {
		return {
			isAllow: true,
			showBtn: true
		}
	},
	methods: {
		setCookieGDPR() {
			this.isAllow = false
			this.$cookie.set("GDPR", true, {expires: '1Y'})
		},
		handleScroll () {
			let form = document.getElementById('form').getBoundingClientRect().top - 125,
				formH = '-' + document.getElementById('form').offsetHeight;
			form <= 0 && form > +formH ? this.showBtn = true : this.showBtn = false;
		}
	},

	created() {
		if (this.$cookie.get('GDPR')) {
			this.isAllow = false
		}
			window.addEventListener('scroll', this.handleScroll);
	},
	computed:  {
		currentRouteName() {
			return this.$route.name
		}
	},

	destroyed () {
		window.removeEventListener('scroll', this.handleScroll);
	}
}
