import getServices from "@/api/services";
import api from '@/api/settings'

export default {
	data() {
		return {
			links: [
				{
					title: 'The company',
					to: 'About'
				},
				{
					title: 'Work',
					to: 'Works'
				},
				{
					title: 'Careers',
					to: 'Careers'
				},
				{
					title: 'Team',
					to: 'Team'
				},
				{
					title: 'Contact us',
					to: 'Contact'
				},
			],
			settings: []
		}
	},
	computed: {
		servicesList() {
			if (this.state.services.data) {
				return this.state.services.data
			}
			return []
		},
	},
	created() {
		getServices.getAll(this)
		api.getAll(this)
			.then(res => {
				this.settings = res.data
			})
	},
	methods: {
		to (link) {
			window.location = link
			// window.open(link, '_blank');
		}
	}
}
