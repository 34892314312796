export default {
	data: () => ({
		group: null,
		links: [
			{
				title: 'home',
				to: 'Home'
			},
			{
				title: 'about',
				to: 'About'
			},
			{
				title: 'services',
				to: 'Services'
			},
			{
				title: 'our work',
				to: 'Works'
			},
			{
				title: 'team',
				to: 'Team'
			},
			{
				title: 'careers',
				to: 'Careers'
			},
			{
				title: 'contact',
				to: 'Contact'
			},
		],
		showBtn: false,
		scrolled: null,
	}),
	watch: {
		group() {
			this.drawer = false
		},
	},
	methods: {
		close() {
			this.drawer = false
		},
	},
}
