import api from '@/api/form'
import {notify} from "@/helpers/notifyHelper";

export default {
  data() {
    return {
      valid: false,
      dialog: false,
      affiliatePlans: [
        'Binary',
        'Unilevel',
        'Matrix',
      ],
      industries: [
        'Cryptocurrency, investment, mining, trading',
        'Health & Wellness',
        'Cosmetics & Personal Care',
        'Fitness & Nutrition',
        'Token & Coin Sales',
        'Crowdfunding',
        'Home & Family Care',
        'Clothing & Accessories',
        'Education & E-Learning',
        'Insurance & Financial Services',
        'Hotel & Ticket Booking',
        'Digital Ads Services',
        'Forex Trading',
        'Online Gaming',
        'Rewards & Loyalty Program',
        'Recharge & Utility Service',
        'Other',
      ],
      formData: {
        fullName: null,
        email: null,
        phone: null,
        affiliatePlan: null,
        industry: null,
      }
    }
  },
  methods: {
    sendForm() {
      if (this.$refs.form.validate()) {
        api.create(this, this.formData)
          .then(response => {
            notify(this, 'success', response.data.message)
            this.dialog = false
          })
      }
    },
    close () {
      this.formData = {
        fullName: null,
        email: null,
        phone: null,
        affiliatePlan: null,
        industry: null,
      }
      this.dialog = false
    }
  },
  mounted() {
    this.$root.$off("ShowModalForm")
    this.$root.$on("ShowModalForm", dialog => {
      if (dialog) {
        this.dialog = dialog
      } else {
        this.close()
      }
    });
  },
}
